<template>
    <div class="detail" :class="[$mq]">
        <!-- <div class="top-actions">
            <div class="top-info">
                <div class="title">
                    <h6 class="name">{{ template.name }}</h6>
                </div>
            </div>
        </div> -->
        <div class="top-container" :class="{ infoOpened: infoIsOpen }">
            <AppccTopDetail @backButton="back" :hasMoreOptions="false" :title="template.name" :hasReopen="false" :hasDelete="false" />
            <input v-if="!hasError" type="text" class="search" v-model="search" :placeholder="$t('vtf.datatable.search')" />
            <div @click="editColumns" class="edit-columns"></div>
            <div @click="sendAddedit('add')" v-if="Object.values(this.audit).length > 0 && this.userAppAccess && checkPermissions()" class="add-report"></div>
        </div>
        <div class="table-container" v-if="searchedValues.length > 0 && !hasError">
            <div class="table" id="datatable" :class="[{ 'hide-scrollbar': ['portrait'].includes($mq) }, { scrollbar: ['landscape', 'desktop'].includes($mq) }]">
                <DataTable @sort="sortTable" :customClass="'audit'" :headers="headers" :values="searchedValues" @sendAddedit="sendAddedit" />
            </div>
            <div class="pagination" v-if="checkConnetion && searchedValues.length > 0 && (page > 1 || num_items * page < results)">
                <div v-if="page > 1" class="item prev" @click="page_prev()"></div>
                <div v-if="num_items * page < results" class="item next" @click="page_next()"></div>
            </div>
        </div>
        <ConnectionBox gType="registerConnection" @reload="reloadAudit" v-else-if="enabledConnexionBox()"></ConnectionBox>
        <EmptyPage v-else-if="searchedValues.length == 0 && !hasError" @buttonAction="sendAddedit" :title="$t('empty_pages.audit_title')" type="record" :description="$t('empty_pages.audit_description')" :buttonText="$t('empty_pages.start')"></EmptyPage>
        <EmptyPage v-if="hasError" :buttonText="$t('empty_pages.return_audits')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_audit')"></EmptyPage>
    </div>
</template>

<script>
import DataTable from '../ui/DataTable.vue'
import AppccTopDetail from '../../layout/AppccTopDetail.vue'
import EmptyPage from '../../layout/EmptyPage.vue'
import { FORMAT } from '@/constants'
import ConnectionBox from '@/components/domain/ui/Box.vue'

export default {
    components: { EmptyPage, AppccTopDetail, DataTable, ConnectionBox },
    props: {
        audit: { type: Object },
        detailId: { type: String },
        hasError: { type: undefined, default: false }
    },
    data() {
        return {
            infoIsOpen: false,
            search: '',
            pagination: {
                sortBy: 'dateUnix',
                rowsPerPage: -1,
                descending: true
            },
            hidden_columns: localStorage.col_audit ? JSON.parse(localStorage.col_audit) : [],
            FORMAT: FORMAT,
            page: 1,
            num_items: 10,
            columnSortedDesc: false,
            columnSortedAsc: false,
            headersConf: localStorage.getItem(`headersConf_${this.detailId}`) ? JSON.parse(localStorage.getItem(`headersConf_${this.detailId}`)) : [],
            isReloaded: false
        }
    },
    computed: {
        checkConnetion() {
            return this.$store.getters['getConnState']
        },
        userAppAccess() {
            return this.$store.getters['loginUser/getHasAccessApp']
        },
        results() {
            return this.$store.getters['audit/getNumberItems'] ? this.$store.getters['audit/getNumberItems'] : false
        },
        template() {
            // ESCONDER BOTTONES
            if (this.hasError) {
                this.$bar.hide()
            }
            return this.$store.getters['audit/getTemplateById'](this.detailId)
        },
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },

        headers: {
            get: function () {
                let schema = this.$store.getters['audit/getHeaders']
                var headers = []

                if (this.$mq == 'portrait') {
                    headers.push({
                        text: this.$t('audit.headers.date'),
                        value: 'simpleDate',
                        sortable: true,
                        visible: true,
                        blocked: true
                    })
                    headers.push({
                        text: this.$t('audit.headers.auditory'),
                        value: 'auditory',
                        sortable: false,
                        visible: true,
                        blocked: true
                    })
                } else {
                    headers.push({
                        text: this.$t('audit.headers.date'),
                        value: 'date',
                        sortable: true,
                        visible: true,
                        blocked: true
                    })
                    headers.push({
                        text: this.$t('audit.headers.status'),
                        value: 'status',
                        type: 'status',
                        sortable: true,
                        visible: true,
                        blocked: true
                    })
                    headers.push({
                        text: this.$t('audit.headers.supervised'),
                        value: 'employee',
                        type: 'employee',
                        sortable: true,
                        visible: true,
                        blocked: true
                    })
                    headers.push({
                        text: this.$t('audit.headers.score'),
                        value: 'score',
                        type: 'score',
                        sortable: true,
                        visible: true,
                        blocked: true
                    })
                    headers.push({
                        text: this.$t('audit.headers.action_plan'),
                        value: 'actionPlans',
                        type: 'actionPlans',
                        sortable: true,
                        visible: true,
                        blocked: false
                    })
                }

                const schemaValues = Object.values(schema)
                if (schemaValues.length > 0) {
                    const titlesSchema = schemaValues.filter((header) => header.type !== 8 && header.type !== 9).map((value) => value)
                    titlesSchema.forEach((header) => {
                        headers.push({
                            text: header.title,
                            value: header.id,
                            sortable: true,
                            type: header.type,
                            visible: true,
                            blocked: false
                        })
                    })
                }

                if (this.headersConf.length > 0) {
                    if (this.headersConf.length !== headers.length) {
                        //Ver campos que tiene headerConf y modificarlos en headers
                        this.headersConf.forEach((headerConf) => {
                            const index = headers.findIndex((header) => header.value === headerConf.value)
                            if (index !== -1) {
                                headers[index].visible = headerConf.visible
                            }
                        })
                        return JSON.parse(JSON.stringify(headers))
                    }
                    return JSON.parse(JSON.stringify(this.headersConf))
                }

                return headers
            },
            set: function (value) {
                this.headers = value
            }
        },
        searchedValues() {
            var self = this
            /* var resultFiltered = self.prepareTable(Object.values(this.audit)).filter(function (obj) {
                return Object.values(obj).some(function (e) {
                    return e ? e.toString().toLowerCase().includes(cleanString(self.search.toLowerCase())) : false
                })
            }) */

            const resultFiltered = Object.values(this.audit).reduce((acc, obj) => {
                if (obj.template_id === self.$route.params.id) {
                    acc[obj.id] = obj
                }
                return acc
            }, {})

            /* var hashIDs = resultFiltered.length > 0 || self.search ? resultFiltered.map((a) => a.id) : false */
            const hashIDs = resultFiltered ? Object.keys(resultFiltered) : false

            if (!fnCheckConnection()) {
                self.fillTable(this.audit, hashIDs)
            }

            return self.fillTable(this.audit, hashIDs)
        }
    },
    methods: {
        reloadAudit() {
            if (fnCheckConnection()) {
                this.$store.dispatch('audit/loadItems', { template: this.$route.params.id })

                this.isReloaded = true
            }
        },
        enabledConnexionBox() {
            if (this.searchedValues.length == 0 && !fnCheckConnection() && !this.isReloaded) {
                return true
            }
        },
        checkPermissions() {
            const template = this.template
            const userRole = this.$store.getters['employee/getRol'](this.user.rol)
            if (userRole.level >= 8) {
                return true
            }

            if (template && template.config && template.config.nRoles) {
                const nRoles = template.config.nRoles
                let isValid = false

                nRoles.forEach((role) => {
                    if (role.roleId == userRole.id && role.permissions.includes('w')) {
                        isValid = true
                        return
                    }
                })
                return isValid
            }

            return true
        },
        editColumns() {
            const self = this
            const headersCopy = JSON.parse(JSON.stringify(this.headers))
            this.$popup.tableColumn({
                title: 'Personalizar columnas',
                textSave: 'Guardar',
                headers: this.headers,
                routePop: self.$route.name,
                callSave: (obj) => {
                    self.headersConf = obj.$data.headers
                    localStorage.setItem(`headersConf_${self.detailId}`, JSON.stringify(obj.$data.headers))
                    self.$popup.close()
                    self.$forceUpdate()
                },
                headers: headersCopy,
                callCancel: function () {
                    this.$popup.close()
                }
            })
        },
        prepareTable(audit) {
            var values = []
            for (var i in audit) {
                var raw = audit[i]
                var row = {}
                // row.edit = "";
                // row.select = "";
                // row.dateUnix = raw.created_date ? moment(raw.created_date).unix() : '-'
                row.date = raw.created_date ? moment(raw.created_date).format('DD/MM/YYYY HH:mm') : '-'
                row.statusB = raw.status === 'completed' ? 0 : 1
                row.employee = raw.employee && raw.employee != null ? raw.employee.name : '-'
                row.id = raw.id ? raw.id : ''

                for (var j in raw.items) {
                    var item = raw.items[j]
                    row.type = item.type

                    row[item.item_id] = item.value
                }

                values.push(row)
            }
            return values
        },
        fillTable(arr, index = false) {
            var values = []
            var self = this
            for (var i in arr) {
                if (!index || index.includes(i)) {
                    var raw = this.audit[i]
                    var row = {}

                    // para agregar el empleado
                    // (raw.employee_ref && raw.employee_ref != null
                    //             ? '<span class="user-color"> <img class="main-img" src="' + css_image_url + 'avatar/' + raw.employee_ref.avatar + '" /><div class="name">' + raw.employee_ref.name + '</div>' + '</span>'
                    //             : Object.keys(raw.employees).length > 0
                    //             ? '<span class="user-color> <img class="main-img" src="' + css_image_url + 'avatar/' + raw.employee_ref.avatar + "><div class='name'>" + raw.employees[Object.keys(raw.employees)[0]].name + '</div>' + '</span>'
                    //             : '')
                    //     row.id = raw.id ? raw.id : ''

                    // TABLE PORTRAIT
                    if (this.$mq == 'portrait') {
                        row.simpleDate = raw.created_date ? "<span style= 'display:none'>" + raw.created_date + `</span><span class="${raw.requireSync ? 'require-sync-icon' : ''}">` + moment(raw.created_date).format('DD/MM/YYYY H:mm') + '</span>' : '-'

                        /* if (raw.requireSync) {
                            row.simpleDate += `<div class="require-sync-icon ${this.$mq}"></div>`
                        } */

                        // COLUMNA "AUDITORÍA"
                        const statusInfo = raw.status ? `<span class='status ${raw.status}'>${this.$t('audit.status.' + raw.status)}</span>` : '-'
                        const finalScoreColor = raw.finalScore && raw.finalScore.color ? `<span class='final-score-color' style='background-color: ${raw.finalScore.color}'></span>` : ''

                        let scoreInfo = ''

                        if (raw.complete_date) {
                            if (raw.finalScore && raw.finalScore.name) {
                                scoreInfo = `
                                <div class='final-score'>
                                ${finalScoreColor} <span class='name-and-score'>${raw.finalScore.name} (${raw.score}%)</span>
                                </div>`
                            } else {
                                scoreInfo = `<span class='name-and-score'> ${raw.score}% </span>`
                            }
                        } else {
                            scoreInfo = "<div class='final-score'><span class='total'> - </span></div>"
                        }

                        row.auditory = `<div class="audit-info">${statusInfo}${scoreInfo}</div>`

                        // <span style='display:none'>${this.addZeros(raw.score, 3)}</span>
                        row.id = raw.id ? raw.id : ''
                    } else {
                        // TABLE LANDSCAPE
                        row.dateUnix = raw.created_date ? moment(raw.created_date).unix() : '-'
                        row.date = raw.created_date ? "<span style= 'display:none'>" + raw.created_date + `</span><span class="${raw.requireSync ? 'require-sync-icon' : ''}">` + moment(raw.created_date).format('DD [' + this.$t('calendar.separator') + '] MMMM [' + this.$t('calendar.separator') + '] YYYY') + '</span>' : '-'

                        if (raw.employee_ref && raw.employee_ref.avatar && raw.employee_ref.avatar.includes('http') && raw.employee_ref.name) {
                            row.employee = '<span class="user-color"> <img class="main-img" src="' + raw.employee_ref.avatar + '" /><div class="name">' + raw.employee_ref.name + '</div>' + '</span>'
                        } else if (raw.employee_ref && raw.employee_ref.avatar && raw.employee_ref.avatar.includes('file') && raw.employee_ref.name) {
                            row.employee = '<div class="img-container"> <img class="main-img" src="' + raw.employee.avatar + '" /><div class="name">' + raw.employee.name + '</div> </div>'
                        } else if (raw.employee_ref && raw.employee_ref.avatar && raw.employee_ref.name) {
                            row.employee = '<span class="user-color"> <img class="main-img" src="' + css_image_url + 'avatar/' + raw.employee_ref.avatar + '" /><div class="name">' + raw.employee_ref.name + '</div>' + '</span>'
                        } else if (Object.keys(raw.employees).length > 0 && raw.employees[Object.keys(raw.employees)[0]].avatar && raw.employees[Object.keys(raw.employees)[0]].avatar.includes('http') && raw.employees[Object.keys(raw.employees)[0]].name) {
                            row.employee = '<span class="user-color> <img class="main-img" src="' + raw.employee_ref.avatar + "><div class='name'>" + raw.employees[Object.keys(raw.employees)[0]].name + '</div>' + '</span>'
                        } else if (Object.keys(raw.employees).length > 0 && raw.employee_ref.avatar && raw.employees[Object.keys(raw.employees)[0]].name) {
                            row.employee = '<span class="user-color> <img class="main-img" src="' + css_image_url + 'avatar/' + raw.employee_ref.avatar + "><div class='name'>" + raw.employees[Object.keys(raw.employees)[0]].name + '</div>' + '</span>'
                        }

                        const onlyScore = raw.completed ? `${raw.completed}<span class='total'>/${raw.total_score} <span class='percent'>(${raw.score}%)</span></span>` : ''
                        const finalScoreColor = raw.finalScore && raw.finalScore.color ? `<span class='final-score-color' style='background-color: ${raw.finalScore.color}'></span>` : ''
                        const finalScore = raw.completed && raw.finalScore && raw.finalScore.name ? `<div class='audit-score'> ${finalScoreColor} <span class='percent'>${raw.finalScore.name} (${raw.score}%)</span></div>` : ''

                        row.score = raw.complete_date ? (raw.finalScore && raw.finalScore.name ? finalScore : onlyScore) : '-'
                        row.id = raw.id ? raw.id : ''
                    }
                    row.requireSync = raw.requireSync ? raw.requireSync : false
                    row.statusB = raw.status === 'completed' ? 0 : 1
                    row.status = raw.status ? "<span class='status " + raw.status + "'>" + this.$t('audit.status.' + raw.status) + '</span>' : '-'

                    if (raw.data) {
                        let percentage = raw.data.actionplans_score

                        if (percentage > 0 && percentage < 100) {
                            row.actionPlans = "<span class='status progress'>" + this.$t('audit.status.progress') + ' (' + percentage + '%)</span>'
                        } else if (percentage == 100) {
                            row.actionPlans = "<span class='status completed'>" + this.$t('audit.status.completed') + ' (' + percentage + '%)</span>'
                        } else {
                            row.actionPlans = "<span class='status tostart'>" + this.$t('audit.status.to_start') + '</span>'
                        }
                    } else {
                        row.actionPlans = "<span class='status'> - </span>"
                    }
                    // log('>> ROWS', row)
                    values.push(row)
                }
            }

            // SORT FOR COLUMNS ASC DESC
            if (self.columnSortedDesc && !self.columnSortedAsc) {
                values = values.sort(function (a, b) {
                    if (b[self.columnSortedDesc] && isNaN(b[self.columnSortedDesc]) && a[self.columnSortedDesc] && isNaN(a[self.columnSortedDesc])) {
                        return a[self.columnSortedDesc].localeCompare(b[self.columnSortedDesc])
                    } else return (b[self.columnSortedDesc] !== null ? b[self.columnSortedDesc] : -Infinity) - (a[self.columnSortedDesc] !== null ? a[self.columnSortedDesc] : -Infinity)
                })
            } else if (self.columnSortedAsc) {
                values = values.sort(function (a, b) {
                    if (b[self.columnSortedAsc] && isNaN(b[self.columnSortedAsc]) && a[self.columnSortedAsc] && isNaN(a[self.columnSortedAsc])) {
                        return b[self.columnSortedAsc].localeCompare(a[self.columnSortedAsc])
                    } else return (a[self.columnSortedAsc] !== null ? a[self.columnSortedAsc] : -Infinity) + (b[self.columnSortedAsc] !== null ? b[self.columnSortedAsc] : -Infinity)
                })
            }
            return values
        },
        back() {
            this.$router.push({ name: 'Audit' })
        },
        sortTable(obj) {
            this.columnSortedDesc = obj.columnSortedDesc
            this.columnSortedAsc = obj.columnSortedAsc
        },
        filterColumns() {
            var self = this
            this.$filter({
                list: this.headers,
                selected: self.hidden_columns,
                callback: function (d) {
                    self.hidden_columns = [...d]
                    self.saveFilter(self.hidden_columns)
                }
            })
            this.$filter.show()
        },
        filter() {
            if (fnCheckConnection()) {
                var self = this
                var params = {
                    id: this.$route.params.id,
                    number_pages: this.page,
                    number_items: this.num_items
                }
                this.$overlay.loading()
                this.$store.dispatch('audit/loadItemsPagination', params).then(function () {
                    self.$overlay.hide()
                })
            }
        },
        scrollDatataTable() {
            setTimeout(() => {
                document.getElementById('datatable').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
            }, 100)
        },
        page_prev() {
            this.search = ''
            this.page -= 1
            this.filter()
            this.scrollDatataTable()
        },
        page_next() {
            this.search = ''
            this.page += 1
            this.filter()
            this.scrollDatataTable()
        },

        // Method to format numbers to order correctly
        addZeros(number, length) {
            var str = '' + number
            while (str.length < length) {
                str = '0' + str
            }
            return str
        },

        saveFilter(filter) {
            if (localStorage) {
                localStorage.col_audit = JSON.stringify(filter)
            }
        },

        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending
            } else {
                this.pagination.sortBy = column
                this.pagination.descending = false
            }
        },

        sendAddedit(id) {
            this.$emit('sendedAddedit', id)
        },

        format(value, type) {
            switch (type) {
                case String(FORMAT.CHECK):
                case String(FORMAT.YESNO):
                case String(FORMAT.YESNONC):
                    return value

                case String(FORMAT.DATE):
                    return moment(value).format('DD/MM/YYYY')

                case String(FORMAT.DATETIME):
                    return value ? moment(value).format('DD/MM/YYYY HH:mm') : ''

                case String(FORMAT.PHOTO):
                case String(FORMAT.SIGNATURE):
                    return value ? '<img src="' + value + '"/>' : value

                case String(FORMAT.TITLE):
                case String(FORMAT.SUBTITLE):
                    return value || '-'

                default:
                    return value
            }
        },
        classes(value, current, type) {
            let val = value

            switch (type) {
                case 'score':
                    return {
                        score: val != '-'
                    }

                case String(FORMAT.TITLE):
                case String(FORMAT.SUBTITLE):
                    val = current ? current.replace('%', '') : 0

                    return {
                        success: val == 100,
                        progress: val > 75 && val < 100,
                        warning: val > 0 && val <= 75,
                        error: val == 0
                    }

                case 'status':
                    return {
                        completed: value.status.includes(this.$t('audit.status.completed')),
                        progress: value.status.includes(this.$t('audit.status.progress')),
                        scheduled: value.status.includes(this.$t('audit.status.scheduled'))
                    }

                default:
                    return ''
            }
        },
        preview(value, type) {
            switch (parseInt(type)) {
                case FORMAT.PHOTO:
                case FORMAT.SIGNATURE:
                    this.$popup.media({ image: value })
                    break
                default:
                    break
            }
        },

        employee(id) {
            return this.$store.getters['employee/getEmployee'](id)
        }
    },
    filters: {
        moment: function (date) {
            return date ? moment(date).fromNow() : '--'
        }
    },

    created() {
        const num_page = this.$store.getters['audit/getNumberPage']
        if (num_page) {
            this.page = num_page
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.audit {
    $column-width: 0%;
    $column-max-width: 0px;
    $content-width: 100%;

    > .content {
        padding-top: 0 !important;

        .detail {
            width: inherit;
            height: 100%;

            .top-container {
                display: flex;
                align-items: stretch;
                background-color: $default-bg-color;
                width: 100%;
                max-width: 100%;
                max-height: 150px;
                padding-bottom: 8px;

                .search {
                    @include display-flex();
                    @include align-items(center);
                    @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
                    @include border-radius(4px);
                    @include font-size(ml);
                    height: 40px;
                    width: 300px;
                    background-color: #fff;
                    padding: 0 60px 0 45px;
                    border-bottom: none;
                    font-family: $text;
                    cursor: text;
                    margin-right: 8px;
                }

                .edit-columns {
                    @include display-flex();
                    @include align-items(center);
                    @include background($image: img('settings.svg'), $size: 20px, $position: center);
                    @include border-radius(4px);
                    @include font-size(ml);
                    height: 40px;
                    width: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    background-color: #fff;
                    font-family: $text;
                    cursor: pointer;
                    margin-right: 8px;
                }

                .add-report {
                    @include display-flex();
                    @include align-items(center);
                    @include background($image: img('add_ffffff.svg'), $size: 20px, $position: center);
                    @include border-radius(4px);
                    @include font-size(ml);
                    height: 40px;
                    width: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    background-color: #1a9980;
                    font-family: $text;
                    cursor: pointer;
                }
            }
            .table-container {
                width: 100%;
                height: calc(100% - 64px);
                overflow: auto;

                .table {
                    //height: 100%;
                    min-width: 800px;
                }
            }
            .pagination {
                width: 100%;
                padding: 6px 0px;
                padding-left: 12px;
                display: block;
                grid-column: 1 / 4;
                height: 45px;

                .item {
                    width: calc(50% - 10px);
                    height: 45px;
                    float: left;
                    @include border-radius(4px);
                    @extend .interaction;

                    &.next {
                        @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                        float: right;
                        margin-right: 8px;
                    }
                    &.prev {
                        @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                    }
                }
            }
            // .table {
            //     padding-left: 16px;
            //     height: calc(100% - 65px);
            // }

            &.desktop {
                .table-container {
                    width: 100%;
                    height: calc(100% - 120px);
                    overflow: auto;
                    padding-left: 10px;

                    .table {
                        //height: calc(100%);
                    }
                }
            }
            &.landscape {
                .table-container {
                    width: 100%;
                    height: calc(100% - 74px);
                    overflow: auto;
                    padding-left: 10px;

                    .table {
                        //height: calc(100%);
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
#content.audit {
    width: 100%;

    > .content {
        padding-right: 0px !important;

        .audit-score {
            display: flex;
            align-items: center;
            gap: 7px;
        }

        .final-score-color {
            border-radius: 50%;
            min-width: 14px;
            width: 14px;
            height: 14px;
        }

        > .detail.portrait {
            width: 100%;
            height: 100%;
            overflow: hidden;

            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding: 0px 0px 16px 0px;
                max-height: 150px;

                .search {
                    display: none;
                }
            }
            .table {
                // height: calc(100% - 120px);
                padding-left: 9px;
                overflow: auto;

                .table-values {
                    @include font-size(xs);
                    .column-values {
                        @include font-size(xs);

                        .audit-info {
                            display: flex;
                            flex-direction: column;
                            align-content: flex-start;
                            align-items: flex-start;
                            gap: 8px;

                            .final-score {
                                display: flex;
                                align-items: center;
                                gap: 0.5em;
                            }

                            .final-score-color {
                                border-radius: 50%;
                                min-width: 14px;
                                width: 14px;
                                height: 14px;
                            }

                            .name-and-score {
                                font-size: 16px !important;
                                font-family: 'Avenir-Regular';
                                margin: 0 !important;
                                padding-top: 2px;
                                line-height: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
